import fit_front from './images/fit_check/front.png';
import fit_frontleft from './images/fit_check/frontleft.png';
import fit_left from './images/fit_check/left.png';
import fit_backleft from './images/fit_check/backleft.png';
import fit_back from './images/fit_check/back.png';
import fit_backright from './images/fit_check/backright.png';
import fit_right from './images/fit_check/right.png';
import fit_frontright from './images/fit_check/frontright.png';

import ski_fit_front from './images/ski_fit_check/front.png';
import ski_fit_frontleft from './images/ski_fit_check/frontleft.png';
import ski_fit_left from './images/ski_fit_check/left.png';
import ski_fit_backleft from './images/ski_fit_check/backleft.png';
import ski_fit_back from './images/ski_fit_check/back.png';
import ski_fit_backright from './images/ski_fit_check/backright.png';
import ski_fit_right from './images/ski_fit_check/right.png';
import ski_fit_frontright from './images/ski_fit_check/frontright.png';

export const norm_fit = [
    fit_front,
    fit_frontleft,
    fit_left,
    fit_backleft,
    fit_back,
    fit_backright,
    fit_right,
    fit_frontright
];

export const ski_fit = [
    ski_fit_front,
    ski_fit_frontleft,
    ski_fit_left,
    ski_fit_backleft,
    ski_fit_back,
    ski_fit_backright,
    ski_fit_right,
    ski_fit_frontright
];