import {norm_fit} from './rot_images';
import {ski_fit} from './rot_images';

import guitar from './images/blender/guitar.png';
import godswords from './images/blender/godswords.png';
import lightsaber from './images/blender/lightsaber.png';

import cycle1 from './images/gen_art/cycle1.png';
import cycle2 from './images/gen_art/cycle2.png';
import cycle3 from './images/gen_art/cycle3.png';

import cat_gif from './images/animation/cat.gif';
import cats from './images/animation/cats.png';

import {useEffect} from 'react';

import './App.css';

//adds picture to middle
function App() {
  useEffect(() => {
    start_rotation();
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-2">
        </div>
        <div className="col-7">
          <div className="row">
            <div className="col-2">
              <div className="h-50"></div>
              <div className="container">
                <button className="cbtn" id="left-butt" onClick={()=>{
                  if (current_set === 0) {
                    current_set = fits.length - 1;
                  }
                  else current_set -= 1;
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2vw" height="2vw" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
                    <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1"/>
                  </svg>
                </button>
              </div>
            </div>
            <div className="col-8">
              <img src={norm_fit[0]} className="img-fluid" alt="rotating" id="fit_rotator"/>
            </div>
            <div className="col-2">
              <div className="h-50"></div>
              <div className="">
                <button className="cbtn" id="right_way" onClick={()=>{
                  if (current_set === fits.length - 1){
                    current_set = 0;
                  }
                  else current_set += 1;
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2vw" height="2vw" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
<                   path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="h-25 d-flex align-items-center">
            <div className="row">
              <div className="col-12">
                <h5>JONATHAN</h5>
                <h5>AGE:&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; 25<br/></h5>
                <h5>OCCUPATION:&emsp;&nbsp; SWE II<br/></h5>
              </div>
            </div>
          </div>
          <div className="h-25"></div>
        </div>
        <div></div>
      </div>
      <div className="row">
        <h3 id="blender">BLENDER</h3>
        <div className="col-4">
          <div>
            <img src={guitar} className="img-fluid" alt="model of a guitar"/>
          </div></div>
        <div className="col-4">
          <div>
            <img src={godswords} className="img-fluid" alt="model of godswords"/>
          </div></div>
        <div className="col-4">
          <div>
            <img src={lightsaber} className="img-fluid" alt="model of a lightsaber"/>
          </div>
        </div>
      </div>
      <div className="row">
        <h3 id="animation">ANIMATION</h3>
        <div className="col-6">
          <div>
            <img src={cats} className="img-fluid" alt="cats sketch"/>
          </div>
        </div>
        <div className="col-6">
          <div>
            <img src={cat_gif} className="img-fluid" alt="cat walking"/>
          </div>
        </div>
      </div>
      <div className="row">
        <h3 id="genart">GENERATIVE ART</h3>
        <div className="col-4">
          <div>
            <img src={cycle1} className="img-fluid" alt="generative art output"/>
          </div></div>
        <div className="col-4">
          <div>
            <img src={cycle2} className="img-fluid" alt="generative art output"/>
          </div></div>
        <div className="col-4">
          <div>
            <img src={cycle3} className="img-fluid" alt="generative art output"/>
          </div>
        </div>
      </div>
    </div> 
  );
}

var fits = [norm_fit, ski_fit];  
var current_set = 0;

function start_rotation() {
  var rotator = document.getElementById("fit_rotator");   
  var delayInSeconds = 0.33;    

  var num = 0;
  var changeImage = function() {
      var len = norm_fit.length;
      rotator.src = fits[current_set][num++];
      if (num === len) {
          num = 0;
      }
  };
  setInterval(changeImage, delayInSeconds * 1000);
  
};

export default App;
