import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

var clicked = false;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

document.getElementById('nav').onclick = function() {
  if (clicked === false){
    document.getElementById('navs').setAttribute('class','opacity-1');
    clicked = true;
  } else {
    document.getElementById('navs').setAttribute('class','opacity-0');
    clicked = false;
  }
};


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
